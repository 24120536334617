const PhoneIcon = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M22.4983 11.9288C22.3988 11.9288 22.3034 11.8892 22.2331 11.8189C22.1628 11.7486 22.1233 11.6532 22.1233 11.5538C22.1233 6.21375 17.9045 1.875 12.707 1.875C12.6076 1.875 12.5122 1.83549 12.4419 1.76516C12.3715 1.69484 12.332 1.59946 12.332 1.5C12.332 1.40054 12.3715 1.30516 12.4419 1.23483C12.5122 1.16451 12.6076 1.125 12.707 1.125C18.3133 1.125 22.8733 5.805 22.8733 11.5612C22.8713 11.6594 22.8309 11.7529 22.7608 11.8216C22.6907 11.8903 22.5965 11.9288 22.4983 11.9288Z" fill="#736E6E"/>
<path d="M18.4123 11.9288C18.3129 11.9288 18.2175 11.8893 18.1472 11.819C18.0768 11.7486 18.0373 11.6532 18.0373 11.5538C18.0554 10.1201 17.5049 8.73762 16.5063 7.70877C15.5076 6.67992 14.1422 6.08846 12.7086 6.06379C12.6091 6.06379 12.5138 6.02428 12.4434 5.95395C12.3731 5.88363 12.3336 5.78825 12.3336 5.68879C12.3336 5.58933 12.3731 5.49395 12.4434 5.42362C12.5138 5.3533 12.6091 5.31379 12.7086 5.31379C14.3401 5.33652 15.8959 6.00547 17.0348 7.17388C18.1737 8.3423 18.8026 9.91476 18.7836 11.5463C18.7846 11.5958 18.7757 11.6451 18.7576 11.6912C18.7394 11.7374 18.7123 11.7794 18.6778 11.815C18.6432 11.8506 18.602 11.8789 18.5564 11.8985C18.5109 11.918 18.4619 11.9283 18.4123 11.9288ZM20.6248 19.8113C20.1461 20.7694 19.467 21.6135 18.6336 22.2863C18.3361 22.522 17.9926 22.6929 17.6251 22.7878C17.2576 22.8828 16.8743 22.8997 16.4998 22.8375C13.0986 22.26 9.74984 20.7675 6.40859 17.4825C3.21734 14.0588 1.72109 10.5638 1.15859 7.07254C1.09634 6.68901 1.11172 6.29688 1.20384 5.9194C1.29595 5.54192 1.46292 5.18679 1.69484 4.87504C2.34343 4.01731 3.17311 3.3131 4.12484 2.81254C4.21625 2.71656 4.32621 2.64014 4.44804 2.58793C4.56987 2.53571 4.70104 2.50879 4.83359 2.50879C4.96614 2.50879 5.09731 2.53571 5.21914 2.58793C5.34097 2.64014 5.45093 2.71656 5.54234 2.81254L8.84609 6.24379C9.04722 6.45966 9.15905 6.74374 9.15904 7.03879C9.15905 7.33384 9.04722 7.61792 8.84609 7.83379L6.50609 10.3725C7.49984 12.5513 11.1711 16.3313 13.2786 17.3513L15.7498 14.94C15.8512 14.8384 15.9716 14.7578 16.1042 14.7029C16.2367 14.6479 16.3788 14.6196 16.5223 14.6196C16.6659 14.6196 16.808 14.6479 16.9405 14.7029C17.0731 14.7578 17.1935 14.8384 17.2948 14.94L20.6323 18.315C20.7306 18.4135 20.8084 18.5304 20.8613 18.6591C20.9141 18.7878 20.941 18.9256 20.9403 19.0647C20.9396 19.2038 20.9113 19.3414 20.8572 19.4695C20.8031 19.5977 20.7241 19.7138 20.6248 19.8113Z" fill="#736E6E"/>
</g>
</svg>

    )
}
export default PhoneIcon