import React from "react";

function Mail(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M7.43543 13.0691H3.20816C2.82089 13.0691 2.50452 13.3854 2.50452 13.7727C2.50452 14.16 2.82089 14.4763 3.20816 14.4763H7.43543C7.82271 14.4763 8.13907 14.16 8.13907 13.7727C8.13907 13.38 7.82271 13.0691 7.43543 13.0691ZM6.7318 18C6.7318 17.6127 6.41543 17.2963 6.02816 17.2963H1.79543C1.40816 17.2963 1.0918 17.6073 1.0918 18C1.0918 18.3873 1.40816 18.7036 1.79543 18.7036H6.02271C6.41543 18.7036 6.7318 18.3873 6.7318 18ZM7.43543 21.5236H3.20816C2.82089 21.5236 2.50452 21.84 2.50452 22.2273C2.50452 22.6145 2.82089 22.9309 3.20816 22.9309H7.43543C7.82271 22.9309 8.13907 22.6145 8.13907 22.2273C8.13907 21.8345 7.82271 21.5236 7.43543 21.5236ZM23.0572 18.6982L34.3154 9.49089C33.9282 9.08725 33.3827 8.8418 32.7991 8.8418H11.6573C11.0682 8.8418 10.5282 9.08725 10.1409 9.49089L21.3991 18.7036C21.8791 19.0963 22.5772 19.0963 23.0572 18.6982Z"
          fill="#736E6E"
        />
        <path
          d="M34.8049 10.9089L23.9504 19.7943C23.4486 20.2034 22.8376 20.4052 22.2267 20.4052C21.6158 20.4052 21.0049 20.2034 20.5031 19.7943L9.55583 10.8325C9.55038 10.838 9.55038 10.8325 9.54492 10.838V25.7452C9.54492 26.5252 10.1776 27.1525 10.9522 27.1525H33.4958C34.2758 27.1525 34.9031 26.5198 34.9031 25.7452V10.9525C34.8595 10.9307 34.854 10.9307 34.8049 10.9089ZM17.0886 20.6125L13.5649 24.1362C13.1995 24.5289 12.7086 24.2725 12.5667 24.1362C12.294 23.8634 12.294 23.4162 12.5667 23.138L16.0904 19.6143C16.3631 19.3416 16.8104 19.3416 17.0886 19.6143C17.3667 19.8871 17.3667 20.3343 17.0886 20.6125ZM31.8813 24.1307C31.7449 24.2671 31.314 24.5125 30.8831 24.1307L27.3595 20.6071C27.0867 20.3343 27.0867 19.8871 27.3595 19.6089C27.6322 19.3307 28.0795 19.3362 28.3577 19.6089L31.8813 23.1325C32.1595 23.4107 32.1595 23.858 31.8813 24.1307Z"
          fill="#736E6E"
        />
      </g>
    </svg>
  );
}

export default Mail;
