import './index.scss'

const HireButton = () => {
    return(
        <div className='hire_button'>
            <a href="/hire">
                Hire us
            </a>      
        </div>
        
    )
}
export default HireButton